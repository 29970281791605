import { OnlyHeaderLayoutPage, type OnlyHeaderLayoutPageProps } from '@ori-appshell/layouts';
import type { TranslationsWithNamespaces } from '@ori/i18n';
import { LoggerProvider } from '@ori/logger';
import type { PropsWithChildren } from 'react';
// If needed to be specific for OnlyHeader adjust or replace
import { LayoutContentLoader } from '~/components/LayoutContentLoader';
import { catalogueMainLogger } from '~/logger';

export type CatalogueLayoutProps<T extends TranslationsWithNamespaces = TranslationsWithNamespaces> =
  OnlyHeaderLayoutPageProps<T>;
interface IndexProps<T extends TranslationsWithNamespaces = TranslationsWithNamespaces> {
  layoutPage: OnlyHeaderLayoutPageProps<T>;
  useOpenedMinibagOnDesktop?: boolean;
}
export const CatalogueLayout = ({
  layoutPage,
  useOpenedMinibagOnDesktop = false,
  children,
}: PropsWithChildren<IndexProps>) => (
  <OnlyHeaderLayoutPage {...layoutPage}>
    <LoggerProvider logger={catalogueMainLogger}>
      <LayoutContentLoader useOpenedMinibagOnDesktop={useOpenedMinibagOnDesktop}>{children}</LayoutContentLoader>
    </LoggerProvider>
  </OnlyHeaderLayoutPage>
);
